<template>
    <div class="px-3">
        <div class="flex place-content-between">
            <div class="py-1">
                <PLogo />
            </div>
            <UTooltip text="Fechar menu">
                <UButton icon="i-heroicons-chevron-double-left-20-solid" color="gray" variant="ghost"
                    class="rounded-none" @click="emit('closeSidebar')"></UButton>
            </UTooltip>
        </div>
        <div class="flex flex-col gap-6 mt-4 ">
            <div v-if="workspaces.length > 1">
                <label class="text-gray-400 font-semibold uppercase text-xs">Workspace</label>
                <div class="mt-2">
                    <USelectMenu :loading="loading" v-model="workspace" option-attribute="name" value-attribute="_id"
                        :options="workspaces" variant="outline" selectedIcon="null" class="">

                        <template #leading>
                            <UAvatar :src="`https://peoplelabs.blob.core.windows.net/logos/${workspace}.png`" size="2xs"
                                :alt="workspaceName">
                            </UAvatar>
                        </template>

                        <template #label>
                            <span class="truncate">{{ workspaceName }}</span>
                        </template>

                        <template #option="{ option: company }">
                            <UIcon v-if="company._id == 0" name="i-heroicons-plus"></UIcon>
                            <UAvatar v-else :src="`https://peoplelabs.blob.core.windows.net/logos/${company._id}.png`"
                                size="2xs" :alt="company.name">
                            </UAvatar>
                            <span class="truncate">{{ company.name }}</span>
                        </template>
                    </USelectMenu>
                </div>
            </div>
            <div class="">
                <label class="text-gray-400 font-semibold uppercase text-xs">Menu</label>
                <div class="mt-2">
                    <UVerticalNavigation :links="menu" :ui="{badge:{base:'rounded', color:'green'}}">
                        <template #icon="{ link }">
                            <UIcon :name="link.icon" class="text-primary" />
                        </template>
                    </UVerticalNavigation>
                </div>
            </div>
            <div v-if="!premium && workspaces.length > 1">
                <a href="/plans" class="relative group block w-full overflow-hidden rounded-md h-36">
                    <div
                        class="absolute inset-0 bg-cover bg-center transition-transform duration-300 group-hover:scale-110 bg-[url('https://peoplelabs.blob.core.windows.net/public/bg-header-2.webp')]">
                    </div>
                    <div
                        class="relative z-10 h-full bg-primary-500 bg-opacity-80 group-hover:bg-primary-700 group-hover:bg-opacity-90 flex justify-center items-center p-3">
                        <div>
                            <h1 class="text-white drop-shadow font-bold text-xl text-center mb-2">Assine Agora</h1>
                            <p class="text-white drop-shadow text-center text-sm">Tenha vagas ilimitadas a partir de <b>R$ 69,90/mês</b></p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useNotificationStore } from "~/store/notificationStore";
import { useIntervalFn } from '@vueuse/core'

const notificationStore = useNotificationStore();
const modules = await useModules()
const currentUser = await useCurrentUser()
const router = useRouter()

const { data: workspaces, pending: loading } = await useAsyncData('workspaces', () => $fetch('/api/workspace'))
const { data: premium } = await useAsyncData('premium', () => $fetch('/api/subscription/premium'))

const emit = defineEmits(['closeSidebar'])

workspaces.value.push({
    _id: 0,
    name: 'Criar nova empresa'
})

const workspace = ref(currentUser.company
    ? (workspaces.value.find(x => x._id == currentUser.company) || workspaces.value[0])._id
    : 0)

watch(workspace, async (newWorkspace) => {
    if (newWorkspace && newWorkspace !== '0') {
        try {
            await $fetch('/api/workspace/change', {
                method: 'POST',
                body: {
                    company: newWorkspace
                }
            });

            if (router.currentRoute.value.name !== 'index') {
                await router.push('/');
            } else {
                router.go(0);
            }
        } catch (error) {
            console.error("Erro ao trocar de workspace:", error);
        }
    } else {
        router.push('/company/onboarding');
    }
});

const workspaceName = computed(() => {
    const currentWorkspace = workspaces.value.find(x => x._id === workspace.value);
    return currentWorkspace ? currentWorkspace.name : 'Nome da Empresa';
});


const links = [{
    label: currentUser.displayName,
    to: '/user',
    icon: 'i-heroicons-user-20-solid'
}]
// Menu inicial
const home = ref([
    {
        label: 'Home',
        icon: 'i-heroicons-home-20-solid',
        to: '/'
    },
    {
        label: 'Caixa de entrada',
        icon: 'i-heroicons-inbox-20-solid',
        to: '/inbox',
        badge: notificationStore.unreadCount
    }
])

const newCompany = ref([
    {
        label: 'Cadastrar empresa',
        icon: 'i-heroicons-users-20-solid',
        to: '/company/onboarding'
    }
])

// Definição do menu com base no número de workspaces
const menu = computed(() =>
    workspaces.value.length > 1 ? [home.value, modules, links] : [home.value, newCompany.value, links]
)

// Atualiza a badge automaticamente quando unreadCount muda
watchEffect(() => {
    const inboxItem = home.value.find(item => item.to === '/inbox')
    if (inboxItem) {
        inboxItem.badge = notificationStore.unreadCount
    }
})
</script>