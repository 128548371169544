<template>
    <div class="bg-white dark:bg-gray-950 min-h-screen">
        <div v-if="loading" class="absolute top-0 w-full">
            <UProgress animation="carousel" size="xs" />
        </div>
        <div class="flex h-screen overflow-hidden">
            <div v-if="sideBar.opened" class="w-56 pt-1 pe-1 ">
                <SidebarLeft @closeSidebar="toggleSideBar" />
            </div>
            <div class="flex flex-1 flex-col w-full">
                <div v-if="currentUser.verificationCode" class="p-2 bg-amber-400 text-black">
                    <p class="text-sm flex items-center gap-1">
                        <UIcon name="i-heroicons-exclamation-circle" class="w-6 h-6" />
                        Seu e-mail {{ currentUser.email }} não foi verificado. Verifique sua caixa
                        de entrada ou <UButton @click="sendVerificationEmail" variant="link" :padded="false"
                            color="white">
                            clique aqui para reenviar o link de verificação</UButton>.
                    </p>
                </div>
                <div class="relative">
                    <div class="flex justify-between p-1 ">
                        <div class="flex items-center">
                            <UTooltip v-if="!sideBar.opened" text="Abrir menu">
                                <UButton icon="i-heroicons-bars-3-20-solid" color="gray" variant="ghost"
                                    class="rounded-none" @click="toggleSideBar"></UButton>
                            </UTooltip>
                            <div class="ms-1">
                                <PBreadcrumbs />
                            </div>
                        </div>
                        <div>
                            <UTooltip text="Alternar modo escuro/claro">
                                <UButton :icon="!isDark ? 'i-heroicons-moon' : 'i-heroicons-sun'" variant="ghost"
                                    @click="isDark = !isDark" />
                            </UTooltip>
                            <UTooltip text="Caixa de entrada">
                                <UChip v-if="notificationStore.unreadCount" :text="notificationStore.unreadCount" size="xl" inset
                                    color="green">
                                    <UButton to="/inbox" icon="i-heroicons-inbox" variant="ghost" />
                                </UChip>
                                <UButton v-else to="/inbox" icon="i-heroicons-inbox" variant="ghost" />
                            </UTooltip>
                            <UTooltip text="Novidades">
                                <UButton id="beamerBt" icon="i-heroicons-newspaper" variant="ghost" />
                            </UTooltip>
                            <UTooltip text="Configurações">
                                <UButton to="/settings" icon="i-heroicons-cog-6-tooth" variant="ghost" />
                            </UTooltip>
                        </div>
                    </div>
                </div>
                <div
                    class="flex flex-grow-0 flex-col h-screen overflow-y-auto rounded-tl-xl p-2 bg-gradient-to-bl from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 border-l border-t dark:border-black">
                    <div class="pb-24">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useNotificationStore } from "~/store/notificationStore";
import { useIntervalFn } from '@vueuse/core'

const notificationStore = useNotificationStore();
const { sideBar, toggleSideBar } = useSideBar()

const currentUser = await useCurrentUser()
const toast = useToast()

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    }
})

const colorMode = useColorMode();
const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    }
})

async function sendVerificationEmail() {
    try {
        await $fetch('/api/user/verifyemailsend', { method: 'POST' });

        toast.add({
            title: 'Link enviado',
            description: 'Procure o link de verificação na sua caixa de entrada.',
            icon: "i-heroicons-envelope-20-solid",
            color: "green"
        });
    } catch (error) {
        console.error('Erro ao enviar e-mail de verificação:', error);

        toast.add({
            title: 'Erro ao enviar e-mail',
            description: 'Tente novamente mais tarde.',
            icon: "i-heroicons-exclamation-circle-20-solid",
            color: "red"
        });
    }
}

const { pause } = useIntervalFn(notificationStore.fetchUnreadCount, 60 * 1000)
onMounted(()=>{
    notificationStore.fetchUnreadCount();
})
onUnmounted(pause)

</script>